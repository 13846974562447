export default [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    customRender: (value, item) => {
      return item.material_item.number;
    },
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    customRender: (value, item) => {
      return item.material_item.name;
    },
  },
  {
    title: "产品规格",
    dataIndex: "spec",
    customRender: (value, item) => {
      return item.material_item.spec;
    },
  },
  {
    title: "产品单位",
    dataIndex: "unit",
    customRender: (value, item) => {
      return item.material_item.unit;
    },
  },
  {
    title: "库存数量",
    dataIndex: "total_quantity",
    width: 100,
  },
  {
    title: "批次编号",
    dataIndex: "number",
    width: 160,
  },
  {
    title: "库存金额",
    dataIndex: "total_amount",
    width: 100,
  },
  {
    title: "产品分类",
    dataIndex: "category",
    customRender: (value, item) => {
      return item.material_item.category_items.map((item) => item.name).join(", ");
    },
  },
  {
    title: "库龄(月)",
    dataIndex: "stock_age",
    width: 100,
  },
  {
    title: "备注",
    dataIndex: "material_remark",
    customRender: (value, item, index) => {
      return item.material_item.remark;
    },
  },
  {
    title: "客户",
    dataIndex: "client_name",
    customRender: (value, item, index) => {
      return item.client_item.name;
    },
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    customRender: (value, item, index) => {
      return item.warehouse_item.name;
    },
  },
  {
    title: "库区",
    dataIndex: "area_name",
    customRender: (value, item, index) => {
      return item.area_item.name;
    },
  },
  {
    title: "库位",
    dataIndex: "location_number",
    customRender: (value, item, index) => {
      return item.location_item.number;
    },
  },
  {
    title: "入库日期",
    dataIndex: "stock_in_date",
    width: 100,
  },
];
