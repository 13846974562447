import request from "@/utils/request";

// InventoryReport
export function inventoryReportList(params) {
  return request({ url: `/batchs/`, method: "get", params });
}

export function inventoryReportTotalQuantity(params) {
  return request({ url: `/batchs/total_quantity/`, method: "get", params });
}


export function inventoryReportExport(params) {
  return request({
    url: "/batchs/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// StockInReport
export function stockInReportList(params) {
  return request({ url: `/stock_in_reports/`, method: "get", params });
}

export function stockInReportExport(params) {
  return request({
    url: "/stock_in_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// StockOutReport
export function stockOutReportList(params) {
  return request({ url: `/stock_out_reports/`, method: "get", params });
}

export function stockOutReportExport(params) {
  return request({
    url: "/stock_out_reports/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// InventoryWarning
export function inventoryWarningList(params) {
  return request({ url: `/inventories/`, method: "get", params });
}

export function inventoryWarningExport(params) {
  return request({
    url: "/inventories/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}
